import React, { useEffect, useState, useCallback } from 'react';
import api from './axiosConfig';

// Funkcja pomocnicza do obsługi API
const fetchData = async (url, params = {}) => {
    try {
        const response = await api.get(url, { params });
        return response.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};

const MoviesCatalog = ({ searchQuery, onEdit, isAdmin = false, onError }) => {
    const [movies, setMovies] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [media, setMedia] = useState({ preview: {}, thumbnail: {} });
    const [hoveredMovies, setHoveredMovies] = useState({});
    const limit = 5;

    const totalPages = Math.ceil(total / limit);

    // Funkcja pobierająca filmy
    const fetchMovies = useCallback(async () => {
        setLoading(true);
        try {
            const offset = (currentPage - 1) * limit;
            const data = await fetchData('/MoviesCatalog/get-videos', {
                limit,
                offset,
                query: searchQuery || '',
            });

            setMovies(data.movies.$values || []);
            setTotal(data.total);
        } catch (err) {
            if (err.message === 'access denied') {
                setError('access denied');
                onError && onError('access denied');
            }
        } finally {
            setLoading(false);
        }
    }, [currentPage, searchQuery]);

    // Funkcja pobierająca multimedia (preview i thumbnail)
    const fetchMedia = useCallback(async (movieTitle, type) => {
        const endpoint = type === 'preview' ? 'preview' : 'thumbnail';
        const extension = type === 'preview' ? 'mp4' : 'jpg';

        try {            
            const response = await api.get(`/MoviesCatalog/${endpoint}/${movieTitle}/thumbnail.${extension}`, {
                responseType: 'blob',
            });
            const url = URL.createObjectURL(response.data);

            setMedia((prev) => ({
                ...prev,
                [type]: { ...prev[type], [movieTitle]: url },
            }));
        } catch (err) {
            console.error(`Error fetching ${type} for movie ${movieTitle}:`, err);
        }
    }, []);

    // Pobranie filmów przy montowaniu i zmianie parametrów
    useEffect(() => {
        fetchMovies();
    }, [fetchMovies]);

    // Pobieranie miniatur na początku
    useEffect(() => {
        movies.forEach((movie) => {
            fetchMedia(movie.title, 'thumbnail');
        });
    }, [movies, fetchMedia]);

    // Obsługa zdarzeń najechania myszką (hover) i kliknięcia
    const handleMouseEnter = (movieId, movieTitle) => {
        setHoveredMovies((prev) => ({ ...prev, [movieId]: true }));

        // Pobranie podglądu wideo tylko przy pierwszym najechaniu
        if (!media.preview[movieTitle]) {
            fetchMedia(movieTitle, 'preview');
        }
    };

    const handleMouseLeave = (movieId) => {
        setHoveredMovies((prev) => ({ ...prev, [movieId]: false }));
    };

    const handleTouchStart = (movieId) => {
        setHoveredMovies((prev) => ({
            ...prev,
            [movieId]: !prev[movieId],
        }));
    };

    const handleDeleteMovie = async (movieId) => {
        try {
            await api.delete('/ManageFiles/delete', { params: { id: movieId } });
            setMovies((prev) => prev.filter((movie) => movie.id !== movieId));
        } catch (err) {
            console.error(`Error deleting movie ${movieId}:`, err);
        }
    };

    if (error === 'access denied') {
        return <h1>ACCESS DENIED</h1>;
    }

    return (
        <div className="container">
            {loading ? (
                <p>Ładowanie danych...</p>
            ) : (
                <>
                    <div className="row">
                        {movies.map((movie) => (
                            <div key={movie.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                                <a href={`video/${movie.id}`}>
                                    <div
                                        className="card"
                                        style={{ cursor: 'pointer' }}
                                        onMouseEnter={() => handleMouseEnter(movie.id, movie.title)}
                                        onMouseLeave={() => handleMouseLeave(movie.id)}
                                        onTouchStart={() => handleTouchStart(movie.id)}
                                    >
                                        <div className="ratio ratio-16x9">
                                            {!hoveredMovies[movie.id] ? (
                                                <img
                                                    src={media.thumbnail[movie.title]}
                                                    alt={`Thumbnail for ${movie.title}`}
                                                    className="card-img-top w-100 h-100"
                                                    style={{ objectFit: 'cover' }}
                                                />
                                            ) : (
                                                <video
                                                    src={media.preview[movie.title]}
                                                    className="card-img-top w-100 h-100"
                                                    muted
                                                    playsInline
                                                    autoPlay
                                                    style={{ objectFit: 'cover' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <h5 className="card-title text-center">{movie.title}</h5>
                                </a>
                                {isAdmin && (
                                    <div className="card-body p-2">
                                        <button onClick={() => onEdit(movie)}>Edytuj</button>
                                        <button onClick={() => handleDeleteMovie(movie.id)}>Usuń</button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Paginacja */}
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center mt-4">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    Poprzednia
                                </button>
                            </li>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <li
                                    key={index}
                                    className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => setCurrentPage(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                >
                                    Następna
                                </button>
                            </li>
                        </ul>
                    </nav>
                </>
            )}
        </div>
    );
};

export default MoviesCatalog;
