import React, { useState } from 'react';
import './Register.css';
import api from './axiosConfig'; // Zmienione na to samo co w Login.js

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        // Sprawdzenie, czy hasła są zgodne
        if (password !== confirmPassword) {
            setError('Hasła nie są zgodne!');
            return;
        }

        try {
            const response = await api.post('/Account/register', {
                email,
                password
            });
            setSuccess("Rejestracja zakończona sukcesem! Możesz się zalogować.");
            return response;
        } catch (err) {
            setError(err.response?.data?.message || "Wystąpił problem podczas rejestracji.");
        }
    };

    return (
        <div className="register">
            <h2>Rejestracja</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Hasło:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Potwierdź hasło:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Zarejestruj się</button>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </form>
        </div>
    );
};

export default Register;
