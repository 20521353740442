import React, { useState } from 'react';
import '../style.css'

const Search = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  // Funkcja obsługująca zmiany w polu tekstowym
  const handleInputChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    onSearch(newQuery); // Wywołanie funkcji przekazanej w propsach z aktualnym zapytaniem
  };

  return (
    <div class="search-bar">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search files by name..."
        style={{
          width: '100%',
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid #ccc',
        }}
      />
    </div>
  );
};

export default Search;
