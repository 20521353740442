import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Hls from 'hls.js';
import api from './axiosConfig';

const VideoPlayer = () => {
    const { id } = useParams();  // Pobiera id z parametrów URL
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [manifestUrl, setManifestUrl] = useState('');

    useEffect(() => {
        const fetchManifest = async () => {
            try {
                const response = await api.get(`/MediaStreaming/playlist/${id}`);
                const data = await response.data;
                const blob = new Blob([data], { type: 'application/vnd.apple.mpegurl' });
                const url = URL.createObjectURL(blob);
                setManifestUrl(url);
            } catch (error) {
                console.error('Error fetching manifest:', error);
            }
        };
        
        fetchManifest();
    }, [id]);

    useEffect(() => {
        const video = videoRef.current;

        if (Hls.isSupported() && video && manifestUrl) {
            const token = localStorage.getItem('token');

            const hls = new Hls({
                xhrSetup: (xhr) => {
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                },
                // // Parametry buforowania
                maxBufferLength: 200,        // Buforowanie do 60 sekund w przód
                maxBufferSize: 100 * 1024 * 1024,  // Zwiększenie rozmiaru bufora (100MB)
                maxMaxBufferLength: 120,    // Maksymalna długość bufora w sekundach
                // startLevel: 0,              // Rozpoczynaj od pierwszego dostępnego poziomu jakości
                autoStartLoad: true,        // Automatyczne ładowanie segmentów
                // startFragPrefetch: true,    // Prefetch pierwszych segmentów
                // fragLoadingTimeOut: 20000,
                // fragLoadingTimeOut: 60,     // Czas oczekiwania na załadowanie segmentu
                // maxBufferHole: 0.5,         // Maksymalna tolerancja na przerwy między segmentami
                // lowLatencyMode: true,       // Włącz tryb niskiej latencji, aby zminimalizować opóźnienia
            });

            hls.loadSource(manifestUrl);
            hls.attachMedia(video);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                if (isPlaying) {
                    video.play().catch(error => {
                        console.error("Error trying to play video:", error);
                    });
                }
            });

            hls.on(Hls.Events.ERROR, (event, data) => {
                console.error('HLS.js error:', event, data);
            });

            return () => {
                hls.destroy();
            };
        } else if (video && video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = manifestUrl;
            video.addEventListener('loadedmetadata', () => {
                if (isPlaying) {
                    video.play().catch(error => {
                        console.error("Error trying to play video:", error);
                    });
                }
            });
        }

        return () => {
            if (video) {
                video.src = '';
                video.load();
            }
        };
    }, [manifestUrl, isPlaying]);

    const handlePlay = () => {
        const video = videoRef.current;
        if (video) {
            video.play().then(() => {
                setIsPlaying(true);
            }).catch(error => {
                console.error("Error trying to play video:", error);
            });
        }
    };

    return (
        <div>
            <h1>Video Streaming App</h1>
            <video ref={videoRef} controls style={{ width: '100%', height: 'auto' }} />
            <button onClick={handlePlay} style={{ marginTop: '10px' }}>
                Play Video
            </button>
        </div>
    );
};

export default VideoPlayer;